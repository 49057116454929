<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" back="Users">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box>

			<app-input-text v-model="model.name" :validation="$v.model.name" label="Name" placeholder="Enter name" />
			<app-input-text v-model="model.email" :validation="$v.model.email" label="Email" placeholder="Enter email" />
			<app-input-text v-model="model.password" :password="true" :validation="$v.model.password" label="Password" placeholder="Enter password" />
			<app-input-select v-model="model.role" :validation="$v.model.role" label="Role" placeholder="Select role" :options="roleOptions" />
			<app-input-suggest v-if="requiresOrganisation" v-model="model.organisation_id" :validation="$v.model.organisation_id" label="Organisation" placeholder="Search organisations" endpoint="organisation" />
			<app-input-suggest v-if="requiresGroup" :params="{ organisation_id: model.organisation_id }" :disabled="!model.organisation_id" v-model="model.group_id" :validation="$v.model.group_id" label="Group" placeholder="Search groups" endpoint="group" />
			<app-input-suggest v-if="supportsCentre" :params="{ organisation_id: model.organisation_id }" :disabled="!model.organisation_id" v-model="model.centre_id" :validation="$v.model.centre_id" label="Local Centre" placeholder="Search local centres" endpoint="centre" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixins/form'
import { required, email, requiredIf } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: false,
				name: '',
				email: '',
				role: '',
				organisation_id: 0,
				group_id: 0,
				centre_id: 0,
				password: ''
			},
			endpoint: 'user',
			noun: 'user'
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			email: {
				email
			},
			role: {
				required
			},
			password: {
				required: requiredIf('isNew')
			},
			organisation_id: {
				required: requiredIf('requiresOrganisation')
			},
			group_id: {
				required: requiredIf('requiresGroup')
			},
			centre_id: {}
		}
	},

	computed: {

		requiresOrganisation: function() {

			return this.$_.contains(['organisation', 'contributor', 'user'], this.model.role) && this.$role === this.$constants.role.global

		},

		requiresGroup: function() {

			return this.$_.contains(['contributor', 'user'], this.model.role)

		},

		supportsCentre: function() {

			return this.$_.contains(['user'], this.model.role)

		},

		roleOptions: function() {

			var roles = []

			if (this.$role === this.$constants.role.global) {

				roles = this.$constants.roleName

			} else if (this.$role === this.$constants.role.organisation) {

				roles = {
					organisation: 'Organisation Admin',
					contributor: 'Contributor',
					user: 'User'
				}

			}

			return roles

		}

	},

	methods: {

		setDefaults: function() {

			if (this.$role !== this.$constants.role.global) {

				this.model.organisation_id = this.$store.getters['organisation']

			}

		}

	}

}

</script>

<style scoped>

</style>